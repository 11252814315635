import { Box, Button, FlexBox, P } from '@vp/swan'
import { useEffect, useState } from 'react'
import { PolicyLinks } from '../../constants/urls'
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../utils/storage.utils'
import { cookieNotice, message } from './cookie-notifier.module.scss'

const USER_COOKIE_CONSENT_KEY = 'vds-site-v2-user_cookie_consent'

export const CookieNotifier = () => {
  const [showConsent, setShowConsent] = useState(false)
  useEffect(
    () => setShowConsent(!getFromLocalStorage(USER_COOKIE_CONSENT_KEY)),
    [],
  )
  const acceptCookieConsent = () => {
    setToLocalStorage({ key: USER_COOKIE_CONSENT_KEY, value: true })
    setShowConsent(false)
  }
  if (!showConsent) return null
  return (
    <FlexBox
      alignItems="center"
      justifyContent="space-between"
      className={cookieNotice}
    >
      <P my={0} mr={{ xs: 0, sm: '7' }} className={message}>
        <strong>Cookie Consent:</strong> We use cookies to enable the site to
        function correctly and to analyze site usage. For more information, see
        our{' '}
        <a target="_blank" rel="noreferrer" href={PolicyLinks.privacy}>
          Privacy and Cookie Policy
        </a>
        .
      </P>
      <Box mt={{ xs: '7', sm: 0 }}>
        <Button skin="primary" width="full-width" onClick={acceptCookieConsent}>
          Accept
        </Button>
      </Box>
    </FlexBox>
  )
}
