import { StyleSpaceWithDensity } from '@vp/swan'

export type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export const sectionHeadingSpace: Record<
  HeadingElement,
  {
    mt: Extract<
      StyleSpaceWithDensity,
      'between-sections' | 'between-subsections'
    >
  }
> = {
  h1: { mt: 'between-sections' },
  h2: { mt: 'between-sections' },
  h3: { mt: 'between-subsections' },
  h4: { mt: 'between-subsections' },
  h5: { mt: 'between-subsections' },
  h6: { mt: 'between-subsections' },
}
