import { Button, Icon, useScreenClass, VisuallyHidden } from '@vp/swan'
import React, { useRef } from 'react'
import { useFabPosition } from '../../hooks/use-fab-position'
import { feedbackBtn, feedbackIcon } from './feedback-fab.module.scss'

type FeedbackFabProps = {
  pageName?: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  showPageName: boolean
}

/**
 * A button fixed to the bottom-right of the window configured with dynamic copy and any given on-click event.
 */
export const FeedbackFab = ({
  pageName,
  onClick,
  showPageName,
}: FeedbackFabProps) => {
  const screenClass = useScreenClass()
  const btnRef = useRef<HTMLButtonElement>()

  // Positions the button with respect to other existing fabs on the page
  useFabPosition(btnRef)

  const text =
    pageName && showPageName ? `Give feedback on ${pageName}` : `Give feedback`

  const isMobile = ['xs', 'sm'].includes(screenClass)

  return (
    <Button
      className={feedbackBtn}
      skin="primary"
      buttonShape={isMobile ? 'round' : 'standard'}
      onClick={onClick}
      iconPosition="left"
      ref={btnRef}
      id="feedback-fab"
    >
      {isMobile ? <VisuallyHidden>{text}</VisuallyHidden> : text}
      <Icon iconType="liveChat" className={feedbackIcon} />
    </Button>
  )
}
