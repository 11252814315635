import BlockContent from '@sanity/block-content-to-react'
import { PropsWithChildren } from 'react'
import { SanityBlock } from '../../../../../types/gatsby-graphql'
import { sectionHeadingSpace } from '../../../../constants/section-spacing'
import { Heading } from '../../../heading/heading.component'
import { useContentContext, useHeadingId } from '../content.context'

export const BlockRenderer = (
  props: PropsWithChildren<{
    node: SanityBlock
    tocMap: Record<string, string>
  }>,
) => {
  const { style = 'normal' } = props.node
  const headingId = useHeadingId(props?.node?._key)
  const { currentTab } = useContentContext()
  const currentTabQueryParam = currentTab ? `?tab=${currentTab}` : ''
  const sectionLink = `${currentTabQueryParam}#${headingId}`

  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(style)) {
    return (
      <Heading
        element={style}
        headingId={headingId}
        headingLink={sectionLink}
        {...sectionHeadingSpace[style]}
      >
        {props.children}
      </Heading>
    )
  }

  if (style === 'heading') {
    return (
      <Heading element="h3" headingId={headingId} mt={'between-subsections'}>
        {props.children}
      </Heading>
    )
  }

  return BlockContent.defaultSerializers.types.block(props)
}
