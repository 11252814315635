import { Button, SwanCoreStyleProps } from '@vp/swan'
import classNames from 'classnames'
import { TbResize } from 'react-icons/tb'
import { selected } from '../btn-dark-mode/btn-dark-mode.module.scss'
import { useCodeContext } from '../code.context'

export const BtnCompactMode = (props: SwanCoreStyleProps) => {
  const { compactMode, setCompactMode } = useCodeContext()
  const toggleCompactMode = () => {
    setCompactMode(u => !u)
  }
  return (
    <Button
      buttonShape="round"
      {...props}
      onClick={toggleCompactMode}
      className={classNames({ [selected]: compactMode })}
      title={compactMode ? 'Standard mode' : 'Compact mode'}
      skin="tertiary"
    >
      <TbResize size="20" title="Compact mode" />
    </Button>
  )
}
