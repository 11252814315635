import { Box, Card, FlexBox, Icon } from '@vp/swan'
import { chatIcon, feedbackBanner } from './feedback-form-preview.module.scss'
import { Feedback, FeedbackKey } from './feedback-module'
import { FeedbackRating } from './feedback-rating'

type FeedbackFormPreviewProps = {
  feedback: Feedback
  onChange: (feedbackKey: FeedbackKey, newValue: string) => void
}

/**
 * An Alert Box that displays the first "rating" question of the survey and saves the selection in state.
 */
export const FeedbackFormPreview = ({
  feedback,
  onChange,
}: FeedbackFormPreviewProps) => {
  // The feedback prompts relevant to the current page are flagged with `isEnabled: true`.
  // Grab the first relevant "rating" prompt to serve as the preview.
  const feedbackKey = Object.keys(feedback).find(
    key => feedback[key].isEnabled && feedback[key].type === 'rating',
  ) as FeedbackKey

  return (
    <Card pr={5} className={feedbackBanner}>
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap="4"
      >
        <FlexBox alignItems="center" gap={3}>
          <Icon iconType="liveChat" className={chatIcon} />
          <Box as="p" my={0} fontSkin="body-standard-bold">
            {feedback[feedbackKey].prompt}
          </Box>
        </FlexBox>
        <FeedbackRating
          feedbackKey={feedbackKey}
          onSelectedValueChange={onChange}
        />
      </FlexBox>
    </Card>
  )
}
