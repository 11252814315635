import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  StyleBackgroundColor,
  SwanCoreStyleProps,
} from '@vp/swan'
import { FaFillDrip } from 'react-icons/fa6'
import { useCodeContext } from '../code.context'
import { swatch } from './btn-bgc-selector.module.scss'

const BACKGROUNDS: StyleBackgroundColor[] = [
  'transparent',
  'standard',
  'strong',
  'accent',
  'success',
  'warning',
  'error',
  'help',
  'promo',
  'info',
  'black',
  'holiday',
  'holiday-beige-1',
  'holiday-green-1',
  'holiday-green-2',
  'holiday-red-1',
  'holiday-red-2',
]

export const BtnBgcSelector = (props: SwanCoreStyleProps) => {
  const { bgc, setBgc, codeKey } = useCodeContext()
  const updateBgc = (k: StyleBackgroundColor) => {
    setBgc(k)
  }

  const handleDropdownUpdate = () => {
    setTimeout(() => {
      // adding a timeout because the popover might not have rendered while running below logic
      if (bgc) {
        const elem = document.getElementById(`bgc-${codeKey}-${bgc}`)
        if (elem) {
          elem.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          })
          elem.focus()
        }
      }
    }, 0)
  }

  return (
    <Menu onChange={(value: StyleBackgroundColor) => updateBgc(value)}>
      <MenuTrigger>
        <Button
          title="Background color"
          buttonShape="round"
          {...props}
          onClick={handleDropdownUpdate}
          skin="tertiary"
        >
          <FaFillDrip size="20" />
        </Button>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {BACKGROUNDS.map(bg => (
            <MenuItem key={bg} aria-selected={bgc === bg}>
              <Box className={swatch} bgc={bg} />
              {bg}
            </MenuItem>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
