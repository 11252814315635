import { Box, Button, ErrorBoundary, FlexBox, Li, Link, Ul } from '@vp/swan'
import { Maybe, SanityLink } from '../../../types/gatsby-graphql'
import { sectionHeadingSpace } from '../../constants/section-spacing'
import { LinkType, SlugPrefix } from '../../constants/slug-prefix'
import { Heading } from '../heading/heading.component'

export const SchemaLinkCards = ({
  relatedDocuments,
}: {
  relatedDocuments?: Maybe<Array<Maybe<SanityLink>>>
}) => {
  if (
    !relatedDocuments ||
    !Array.isArray(relatedDocuments) ||
    !relatedDocuments.length
  ) {
    return null
  }

  // TODO: This should use generic SchemaLink
  return (
    <ErrorBoundary>
      <Box as="section" mb={{ xs: '5', sm: '7' }}>
        <Heading element="h2" {...sectionHeadingSpace.h2}>
          Related
        </Heading>
        <FlexBox
          flexWrap="wrap"
          flexDirection="row"
          gap={5}
          render={({ className, style, children }) => (
            <Ul className={className} skin="minimal" style={style}>
              {children}
            </Ul>
          )}
        >
          {relatedDocuments.map(doc => {
            if (doc && doc._key) {
              const linkCategory = doc[doc.linkType]
              if (linkCategory) {
                return (
                  <Li key={doc._key}>
                    <Button
                      render={({ className }) => (
                        <Link
                          to={`${SlugPrefix[doc.linkType]}/${
                            linkCategory.slug.current
                          }`}
                          className={className}
                        >
                          {doc[doc.linkType]?.title}
                        </Link>
                      )}
                    ></Button>
                  </Li>
                )
              }
              let pageUrl = ''
              let pageTitle = ''
              let download = null
              if (doc.linkType === LinkType.DownloadExternal) {
                pageUrl = doc.url
                download = doc.downloadAs
                pageTitle = doc.downloadAs
              } else if (doc.linkType === LinkType.DownloadFile) {
                pageUrl = doc.file.asset.url
                download = doc.downloadAs
                pageTitle = doc.downloadAs
              } else if (doc.linkType === LinkType.External) {
                pageUrl = doc.url
                pageTitle = doc.url
              }
              return (
                <Li key={doc._key}>
                  <Button
                    render={({ className }) => (
                      <a
                        href={pageUrl}
                        target="_blank"
                        download={download}
                        rel="noreferrer"
                        className={className}
                      >
                        {pageTitle}
                      </a>
                    )}
                  ></Button>
                </Li>
              )
            }
            return null
          })}
        </FlexBox>
      </Box>
    </ErrorBoundary>
  )
}
