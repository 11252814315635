import {
  AlertBox,
  Box,
  Button,
  FlexBox,
  Link,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
  Spinner,
  Typography,
} from '@vp/swan'
import { FeedbackForm } from './feedback-form'
import { ApiResponseStatus, Feedback, FeedbackKey } from './feedback-module'

type FeedbackModalProps = {
  feedback: Feedback
  onChange: (feedbackKey: FeedbackKey, value: string) => void
  onSubmit: () => void
  apiResponseStatus: ApiResponseStatus
  isModalOpen: any
  onDismiss: any
}

/**
 * A modal for collecting user feedback and then showing a confirmation or error message upon submission.
 *
 * The UX is dependent on the API response status as follows:
 * - IDLE/INVALID - Form for collecting user feedback
 * - SUCCESS - Form is replaced with a confirmation message (yay!)
 * - ERROR - Form is accompanied by an error message with the option to retry
 */
export const FeedbackModal = ({
  feedback,
  onChange,
  onSubmit,
  apiResponseStatus,
  isModalOpen,
  onDismiss,
}: FeedbackModalProps) => {
  return (
    <ModalDialog
      bodyWidth="capped"
      isOpen={isModalOpen}
      onRequestDismiss={onDismiss}
      onlyRenderWhenOpen
    >
      <ModalDialogContent>
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText="Close" />
        </ModalDialogNav>
        <ModalDialogHeader>
          <ModalDialogTitle id={'feedback-form-modal-title'}>
            {apiResponseStatus === 'SUCCESS'
              ? 'Thanks for your feedback!'
              : 'Give feedback'}
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>
          {apiResponseStatus === 'SUCCESS' ? (
            <Typography>
              You’re helping SWAN become a better system. The Design System team
              is always here to help. Reach out to us at{' '}
              <Link
                href="https://vistaprint.slack.com/archives/CB9FVD94J"
                target="_blank"
                rel="noreferrer noopener"
              >
                #help-swan
              </Link>
              .
            </Typography>
          ) : (
            <FeedbackForm
              feedback={feedback}
              onChange={onChange}
              onSubmit={onSubmit}
            />
          )}
        </ModalDialogBody>
        <ModalDialogFooter>
          {/* `role` and `aria-live` inform assistive tech of the alert so it can be announced to the user */}
          <Box role="status" aria-live="polite">
            {apiResponseStatus === 'ERROR' && (
              <AlertBox skin="error">
                Oops! Something went wrong. Please try again.
              </AlertBox>
            )}
          </Box>
          <ModalDialogButtons
            render={({ children, className }) => (
              <FlexBox className={className} justifyContent="flex-end">
                {children}
              </FlexBox>
            )}
          >
            <Button
              skin="secondary"
              disabled={apiResponseStatus === 'PENDING'}
              onClick={onDismiss}
            >
              {apiResponseStatus !== 'SUCCESS' ? 'Cancel' : 'Close'}
            </Button>
            {apiResponseStatus !== 'SUCCESS' && (
              <Button
                onClick={onSubmit}
                disabled={apiResponseStatus === 'PENDING'}
                iconPosition="right"
                skin="primary"
              >
                {apiResponseStatus === 'PENDING' && (
                  <Spinner size="tiny" accessibleText="Submitting" />
                )}
                Submit
              </Button>
            )}
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}
